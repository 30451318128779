<template>
	<div class="page">
		<div class="toolbar" ref="toolbar">
			<div class="tit"> 编辑VIP等级 </div>
			<div class="action-box">
				<el-button @click="save('form')" size="small" icon="el-icon-check" plain
					v-if="$hasAccess('vip.vipcfg/editzk')">保存 </el-button>
			</div>
		</div>
		<el-card class="box-card card">
			<el-form :model="formData" :rules="rules" ref="form" label-width="120px">
				<el-row>
					<!-- VIP减免 -->
					<div class="el-block">
						<div class="row-title">VIP等级配置</div>
						<div class="agent-choose-form">
							<div class="title">
								<span class="tips"></span>
								<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
									@click="onItemAdd">增加档位</el-button>
							</div>
							<div v-for="idx in itemList.length" class="agent-item">
								<span class="tips">周期内总电量</span>
								<el-input-number type="number" :min="10" :max="1000000" :step="10" :precision="0"
									v-model="itemList[idx-1].electric" class="input" size="small" placeholder="电量">
								</el-input-number>
								<span class="tips">度内<!--，减服务费/度；苏州--></span>
								<!--<el-input-number type="number" :min="0.01" :max="1" :step="0.01" :precision="2"
									v-model="itemList[idx-1].sz_discount" class="input" size="small" placeholder="减免金额">
								</el-input-number>
								<span class="tips">无锡</span>
								<el-input-number type="number" :min="0.01" :max="1" :step="0.01" :precision="2"
									v-model="itemList[idx-1].wx_discount" class="input" size="small" placeholder="减免金额">
								</el-input-number>-->
								<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
									@click="onItemDel(idx)">删除</el-button>
							</div>
						</div>
					</div>
				</el-row>
			</el-form>
		</el-card>
	</div>
</template>
<script>
	export default {
		name: 'vip-vipzk-edit',
		components: {},
		data() {
			return {
				fieldCode: 'vip_zk',
				device_id: '',
				// 表单信息
				formData: {},
				// 档位数组
				itemList: [{
					electric: null,
					sz_discount: null,
					wx_discount: null
				}],
				// 表单规则
				rules: {},
				plug_ids: "",
			}
		},
		mounted() {
			this.init()
		},
		async activated() {
			this.init()
		},
		methods: {
			init() {
				this.getVipcfg();
			},
			//获取配置
			async getVipcfg() {
				const params = {
					token: this.$store.state.user.token,
					fieldCode: this.fieldCode
				};
				const res = await this.$api.Vip.VipcfgIndex(params);
				this.itemList = res.fieldValue;
			},
			// 保存
			save(formName) {
				if (!this.checkItem()) return;
				this.$refs[formName].validate(async valid => {
					if (valid) {
						this.formData.fieldValue = JSON.stringify(this.itemList);
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.formData)
						}
						await this.$api.Vip.VipcfgEditZk(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.getVipcfg();
					}
				})
			},
			//增加档位
			onItemAdd: function() {
				this.itemList.push({
					electric: null,
					sz_discount: null,
					wx_discount: null
				});
			},
			//删除档位
			onItemDel: function(idx) {
				this.itemList.splice(idx - 1, 1);
			},
			//检测档位参数
			checkItem: function() {
				this.itemList.forEach(item => {
					if (item.electric == 0 || item.sz_discount == 0 || item.wx_discount == 0 ||
						item.electric == null || item.sz_discount == null || item.wx_discount == null) {
						return false;
					}
				})
				return true;
			}
		}
	}
</script>
<style lang="scss" scoped>
	.agent-choose-form {
		padding-left: 40px;

		.title {
			.tips {
				font-size: 14px;
				color: #606266;
			}

			.add-button {
				margin-left: 20px;
			}
		}

		.tips {
			font-size: 14px;
			color: #606266;
			padding: 6px 6px;
		}

		.agent-item {
			display: flex;
			width: 100%;
			padding: 10px 10px;

			.select {
				margin-left: 10px;
				width: 250px;
			}

			.input {
				margin-left: 10px;
				width: 140px;
			}

			.del-button {
				margin-left: 10px;
			}
		}

		.agent-choose-error-tips {
			margin-left: 20px;
			height: 20px;

			span {
				color: #F56C6C;
				font-size: 14px;
			}
		}
	}
</style>
